<template>
  <div>
    <ft-select @input="editKey($event)" :items="CHOICE_ACTIVE" v-model="isActive" class="pointer">
      <v-chip
          dark
          :color="wsACCENT"
          :style="!isActive ? 'opacity : 0.5' : ''"
          class="px-1 justify-end font-weight-medium pointer"
      >
        <v-icon  class="mr-1" >
          {{  value ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline' }}
        </v-icon>
        <h5 class="mx-2">{{ isActive ? $t('Active') : $t('NotActive')   }}</h5>
        <v-icon>mdi-menu-down</v-icon>
      </v-chip>
    </ft-select>

    <wsDialog v-if="displayDialog"
              v-model="displayDialog"
              :title="title"
              @save="saveServiceKey"
              :loading="LOADING"
    >

      <ws-text-field
          v-model="keyData"
          :placeholder="placeholder"
          :label="label"
          :error="error"
          @input="error = false"
      />

    </wsDialog>


  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "wsBusinessServiceKeySelect",
  props : {
    serviceKey : {
      type : String,
      default : null
    },
    value : {
      type : String
    },
    label : {
      type : String,
      default : 'Service key'
    },
    placeholder : {
      type : String,
      default : 'Enter service key value'
    },
    title : {
      type : String,
      default : 'Set service key'
    }
  },
  data() {
    return {
      isActive : false,
      keyData : null,
      error: false,
      displayDialog : false
    }
  },
  watch : {
    value() {
      this.checkValue()
    }
  },
  methods : {
    ...mapActions('settings',[
      "EDIT_BUSINESS_SERVICE_KEY",
      "REMOVE_BUSINESS_SERVICE_KEY",
    ]),
    editKey(value) {
      this.isActive = false
      if ( !value ) {
        this.removeServiceKey()
        return
      }

      this.keyData = null
      this.displayDialog = true
    },
    async saveServiceKey() {

      let data = {
        key : this.serviceKey,
        value : this.keyData
      }

      let result = await this.EDIT_BUSINESS_SERVICE_KEY(data)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }

      this.$emit('input' , this.keyData)
      this.isActive = true
      this.displayDialog = false
      this.notify(this.$t('ChangesSaved') , 'success')
    },
    async removeServiceKey() {
      let data = {
        key : this.serviceKey
      }
      let result = await this.REMOVE_BUSINESS_SERVICE_KEY(data)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.$emit('input' , null)
    },

    // technical
    checkValue() {
      this.isActive = !!this.value
    }
  },
  mounted() {
    this.checkValue()
  }

}
</script>

<style scoped>

</style>